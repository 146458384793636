
export default class T {

    static sort_by = (field, reverse, primer) => {
        //const sort_by = (field, reverse, primer) => {
        const key = primer ?
        function(x) {
            return primer(x[field])
        } :
        function(x) {
            return x[field]
        };  
        reverse = !reverse ? 1 : -1;  
        return function(a, b) {
            // @ts-ignore
            return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
        }
    }

    static l(message, ...optionalParams) {
        console.log([message, ...optionalParams].join(', '));
    }

    static isNullOrEmpty = (_str) => {
        if ((_str?.length ?? 0) == 0) {
            return true;
        }
        return false;
     }

     static replaceAll(source, find, replacement, toLower) {
    
        var re = new RegExp(find, 'g'); 
        var result = source.replace(re, replacement);
    
        if (toLower == true) {
            result = result?.toLowerCase();
        }
    
        return result;
    }
    
    static mutColor = (col, amt) => {
      
        var usePound = false;
      
        // eslint-disable-next-line eqeqeq
        if (col[0] == "#") {
            col = col.slice(1);
            usePound = true;
        }
      
        var num = parseInt(col,16);
      
        var r = (num >> 16) + amt;
      
        if (r > 255) r = 255;
        else if  (r < 0) r = 0;
      
        var b = ((num >> 8) & 0x00FF) + amt;
      
        if (b > 255) b = 255;
        else if  (b < 0) b = 0;
      
        var g = (num & 0x0000FF) + amt;
      
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
      
        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
      
      }

}
