import React from 'react';
import T from './../../T';
import Moment from 'react-moment';
import 'moment-timezone';
import './NotificationSender.css';
import { JsonEditor as Editor } from 'jsoneditor-react';

const GEN = {  
    st: {
      clickableTitle: (_color, bg) => { return { color: _color, backgroundColor: bg, fontWeight: 'bold', textAlign: 'center', paddingTop: 11, paddingBottom: 11, paddingLeft: 22, paddingRight: 22, cursor: 'pointer' }}
    }
  }

class NotificationSender extends React.Component {
    constructor(props) {
      super(props);
      this.wrapper = React.createRef();
    }
    
    state={
        postUrl: 'https://fcm.googleapis.com/fcm/send',
        jsonContent: defaultBody(this.props.pushToken)
        , showRes: true
    }

    close = () => {
        this.props.pa.toogleNotificationSender();
    }

    render() {

        let _jsonContent = { ...this.state.jsonContent, to: this.props.pushToken };

        return(
            !this.props.enabled ? null :

            <div style={{ width: '100vw', height: '100vh', backgroundColor: '#00000099', position: 'absolute'
                        , display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} 
                onClick={() => { this.close(); }} >            

                <div style={{ width: '60%', height: '80vh', backgroundColor: 'white', borderRadius: 22, padding: 22 }} onClick={(e) => { e.stopPropagation(); }} >

                    <form className="form-horizontal" style={{ display: 'flex', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center' }} >
                        <div className='secTitle' >POST URL</div> <div style={{ width: 22 }} />
                        <input type="text" className="form-control" ref={(c) => this.title = c} value={this.state.postUrl} onChange={this.updatePostUrl} />
                    </form>

                    <div style={{ height: '75vh', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Editor style={{  }}
                                value={_jsonContent}
                                onChange={this.handleChange}
                                mode={'text'}
                                innerRef={(aaa) => this.innerRef(aaa)}
                                />


                        <div style={{ width: '40%', height: '100%', marginTop: 18, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                            <div style={{ width: '100%', height: '50%', backgroundColor: '#9c5ac4', display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
                                onClick={() => this.send() } >
                                🚀
                            </div>
                            {
                                !this.state.showRes ? null :
                                <Editor onChange={() => {}}
                                    ref={this.wrapper}
                                    value={this.state?.jsonResult}
                                    mode={'view'}
                                    innerRef={(aaa) => this.innerRefBBB(aaa)}
                                    />
                            }
                        </div>


                    </div>  

                </div>
            

      
            
            </div>
            
        );
    }

    updatePostUrl = (e) => {
        this.setState({ ...this.state, postUrl: e.target.value })
    }

    handleChange = (json) => {
        this.setState({ ...this.state, jsonContent: json })
    }
    
    innerRef = (aaa) => {
        if (aaa != null){
            aaa.style.marginTop='2vh';
            aaa.style.width='80%';
            aaa.style.height='75vh';
        }
    }

    resJsonEditorExpandAll = null;
    innerRefBBB = (bbb) => {
        if (bbb != null) {
            this.resJsonEditorExpandAll = bbb.expandAll;
            bbb.style.width='100%';
            bbb.style.height='50%';

            
        }
    }

    send = () => {

        fetch(this.state.postUrl, { method: 'POST', body: JSON.stringify({ ...this.state.jsonContent, to: this.props.pushToken })
            , headers: { 'Content-type': 'application/json', 'Authorization': 'key=AAAAfAZj8xM:APA91bHsNAgwqSyBufJaJW6DKG3P7qQtcUXGVD-yb80IrfES10NSYyrFSq8nI_yvmn4kFQy2uKE6XfcgS9ghukLfE7DDN_Rj7D-vQ6Up7xkk6xSFddw3N-KJ7SdOMgioA8dOB8Ox1BoG' 
                            } })
        .then(res => res.json())
        .then(obj => {
            this.showResult(obj);
        })
        .catch(err => {
            this.showResult(err);
        })
        ;
    }

    showResult = (obj) => {
        this.setState({ ...this.state, jsonResult: obj, showRes: false});
        setTimeout(() => {
            this.setState({ ...this.state, showRes: true}, () => {
                this.wrapper.current.expandAll();
            });
        }, 100);
    }
}


export default NotificationSender;

const defaultBody = (pushToken) => { return {
    "to": pushToken,
    "notification": {
      "title":"notification title",
      "body":"notification body!",
      "sound": "default"
    },
    "data": {
      "title":"data title",
      "body" : "data body!",
      "sound": "default"
    }}
  }
