import React from 'react';
import T from './../../T';
import { JsonEditor as Editor } from 'jsoneditor-react';
import Moment from 'react-moment';
import 'moment-timezone';
import NotificationSender from './NotificationSender';

const GEN = {  
    st: {
      clickableTitle: (_color, bg, _fw) => { return { color: _color, backgroundColor: bg, fontWeight: _fw ?? 'bold', textAlign: 'center'
        , marginRight: 11, paddingTop: 11, paddingBottom: 11, paddingLeft: 22, paddingRight: 22, cursor: 'pointer' }}
    }
  }

class DeviceInfoScreen extends React.Component {

    constructor(props) {
      super(props);
      
      this.state= {
            jsonContent: null, showView: true
            , canShowNotificationSender: false
            , 
        }
    }
    
    reset = () => {
        this.title = null;
    }

    title = null;
    render() {

        if (this.title == null && !!(this.props.jsonContent)) {
            
            let j = this.props.jsonContent;
            let t = [];
            
            if (!!(j)) {
                
                t.push ( "" + ( j.lastName ? (j.lastName?.substring(0, 10)) : j.lastProfileID ) + "" + (j.lastName?.length > 10 ? '...' : ''));
                
                t.push ( j.isIOS ? 'APPLE' : null );
                let _b = j.DevInfo?.getBrand;
                if (!!_b && _b != 'Apple') t.push ( _b.substring(0, 4).toUpperCase() );
                
                t.push ( j.DevInfo?.getModel );
                // t.push ( j.DevInfo?.DevInfoAsync?.getDeviceName );
                // t.push ( j.DevInfo?.getDeviceId );
            }
            
            this.title = t.filter(p => !T.isNullOrEmpty(p)).join(' ◾ ') + ' +++ ' + this.props.jsonDevKey;
        }

        return(            
            
            !this.props.jsonContent ? null :
            <div style={{ width: '100vw', height: '100vh', backgroundColor: '#eee'
                        , display: 'flex', flexDirection: 'column' }} >

                
                <div style={{ width: '100vw', height: '5vh', backgroundColor: '#555', display: 'flex', flexDirection: 'row' }}>
                    <div style={{width: '100vw', display: 'flex', flexDirection: 'row'}}>
                        <div style={GEN.st.clickableTitle('#ccc', '#444')} onClick={() => this.props.pa.closeDeviceInfoScreen() } >
                            {'✖️'}</div>
                        <div style={GEN.st.clickableTitle('#ccc', '#444')} onClick={() => this.toogleNotificationSender() } >
                            {'🚀'}</div>
                        <div style={GEN.st.clickableTitle('#ccc', '#444', 500)} onClick={() => this.remapName() } >
                            {'rename'}</div>
                        
                        <div style={{ alignSelf: 'center', color: 'white' }} >
                            {this.title}
                        </div>
                        
                        <div style={{ flexGrow: 1 }} />
                        <Moment unix style={{ color: '#ddd', fontWeight: 'bold' }}
                            date={this.props.jsonStamp} format="YYYY/MM/DD - HH:mm" />
                    </div>
                </div>

                {   /* JSON EDITOR */
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        
                        <Editor style={{  }}
                            value={this.state?.jsonContent ?? this.props.jsonContent}
                            onChange={this.handleChange}
                            mode={'text'}
                            innerRef={(aaa) => this.innerRef(aaa)}
                            />

                        <div onClick={() => this.updateView() } style={{ height: '100%', width: 66, backgroundColor:'#333', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                            ▶️
                        </div>

                        { !this.state?.showView ? null :
                            <Editor style={{  }} 
                                value={this.state?.jsonContent ?? this.props.jsonContent}
                                onChange={this.handleChange}
                                mode={'view'}
                                innerRef={(aaa) => this.innerRef(aaa)}
                                />
                        }
                    </div>
                }

                {   /* NOTIFICATION SENDER */
                    //!this.state.canShowNotificationSender ? null :
                    <NotificationSender pa={this} 
                        enabled={this.state.canShowNotificationSender} closeNotificationSender={() => { this.toogleNotificationSender() }} 
                        pushToken={ ( this.state?.jsonContent ?? this.props.jsonContent )?.pushToken } />
                }

            </div>
            
        );
    }

    toogleNotificationSender = () => {
        this.setState({ ...this.state, canShowNotificationSender: !this.state.canShowNotificationSender })
    }

    handleChange = (json) => {
        this.setState({ ...this.state, jsonContent: json })
        //localStorage.setItem('jsonContent', JSON.stringify(json));
    }

    updateView = () => {
        this.setState({ ...this.state, showView: false })
        setTimeout(() => {this.setState({ ...this.state, showView: true })}, 10)
    }

    innerRef = (aaa) => { if (aaa != null) {
            aaa.style.height='95vh';
            aaa.style.width='100vw';
        }
    }

    remapName = () => {
        
      const enteredName = prompt('Enter Name to Remap', this.title?.split(' +++ ')?.[0]);
      if (!T.isNullOrEmpty(enteredName) && !T.isNullOrEmpty(this.props.jsonDevKey)) {
        fetch(`https://api.jvjsc.com:5640/manage/remap_dev?from=${ this.props.jsonDevKey }&to=${ enteredName }`)
          .then(response => response.text())
          .then(data => {
            fetch('https://api.jvjsc.com:5640/manage/dev_map')
            .then(response => response.json())
            .then(data => { this.props.onSuccessfullyRemapDevice({ data: data, enteredName: enteredName }); })
            .catch(err => { console.log('get map err', err) });
          })
          .catch(err => { console.log('remap_dev', err) });
      }
    };
}


export default DeviceInfoScreen;