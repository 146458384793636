import React from 'react';
//import ReactDOM from 'react-dom';
//import logo from './logo.svg';
import './App.css';
import T from './T';

import MainScreen from './screens/MainScreen/MainScreen';
import DeviceInfoScreen from './screens/DeviceInfoScreen/DeviceInfoScreen';
import DevListScreen from './screens/DevListScreen/DevListScreen';
import QRScreen from './screens/QRScreen/QRScreen';
import ColorPicker from './Components/ColorPicker';


class App extends React.Component {
  constructor(props) {
    super(props);
    if (window.location.pathname == '/json') {
      this.state = {
        jsonContent: JSON.parse(localStorage.getItem('jsonContent') ?? '[]')
      }
    }
    this.devInfoS = React.createRef();
    this.mainScreen = React.createRef();
    this.devListS = React.createRef();
  }

  state = {
    jsonContent: null
    , canShowColorPicker: false
  }

  render() {
    return (
      <div>
        <div style={{ position: 'absolute', width: '100vw', height: '100vh' }} >
          <MainScreen ref={this.mainScreen} pa={this} />
        </div>


        { !this.state.showDevList ? null :
          <div style={{ position: 'absolute', width: '100vw', height: '100vh' }} >
            <DevListScreen pa={this} ref={this.devListS} enabled={this.state.showDevList} 
              initDevList={this.state.initDevList}
              devListFilter={this.state.devListFilter}
              onSuccessfullyRemapDevice={(data) => { this.onSuccessfullyRemapDevice(data) }}
              />
          </div>
        }        

        
        <div style={{ position: 'absolute', width: this.state.jsonContent ? '100vw' : 0, height: this.state.jsonContent ? '100vh' : 0 }} >
          <DeviceInfoScreen ref={this.devInfoS} pa={this} jsonDevKey={this.state.jsonDevKey} jsonStamp={this.state.jsonStamp} jsonContent={this.state.jsonContent} 
            onSuccessfullyRemapDevice={(data) => { this.onSuccessfullyRemapDevice(data) }}
            />
        </div>

        { !this.state.canShowColorPicker ? null :
          <div style={{ position: 'absolute', width: '100vw', height: '100vh' }} >
            <ColorPicker pa={this} enabled={this.state.canShowColorPicker} />
          </div>
        }

        { !this.state.showQR ? null :
          <div style={{ position: 'absolute', width: '100vw', height: '100vh' }} >
            <QRScreen pa={this} />
          </div>
        }



      </div>
    );
  }

  onSuccessfullyRemapDevice = (data) => {
    // eslint-disable-next-line no-unused-expressions
    this.mainScreen?.current?.onSuccessfullyRemapDevice(data);
  }

  toogleColorPicker = () => {
    this.setState({ ...this.state, canShowColorPicker: !this.state.canShowColorPicker })
  }

  showColorPicker = () => {
    this.setState({ ...this.state, canShowColorPicker: true })
  }

  showDevListScreen = (devList, devFilter) => {
    this.setState({ ...this.state, devListFilter: devFilter, showDevList: true, initDevList: devList }, () => {
      // eslint-disable-next-line no-unused-expressions
      this.devListS?.current?.reset();
    })
  }
  closeDevListScreen = () => {
    this.setState({ ...this.state, showDevList: false })
  }

  showQRScreen = () => {
    this.setState({ ...this.state, showQR: true }, () => {
    })
  }
  closeQRScreen = () => {
    this.setState({ ...this.state, showQR: false })
  }


  showDeviceInfoScreen = (devKey, json, _jsonStamp) => {
    T.l('showDeviceInfoScreen A', typeof json);
    if (json === undefined || json === null) {
      json = [];
    }
    T.l('showDeviceInfoScreen', devKey, json, _jsonStamp);
    // eslint-disable-next-line no-unused-expressions
    this.devInfoS?.current?.reset();
    this.setState({ ...this.state, jsonDevKey: devKey, jsonStamp: _jsonStamp, jsonContent: json })
  }
  closeDeviceInfoScreen = () => {
    T.l('closeDeviceInfoScreen', +new Date())
    this.setState({ ...this.state, jsonContent: null })
  }

}

export default App;