import React from 'react';

import T from './../../T';

import 'jsoneditor-react/es/editor.min.css';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment/min/moment-with-locales';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import glob from './../../glob';
import './MainScreen.css';


const GEN = {
  url: (controller, path) => {
    return `https://api.jvjsc.com:5640/${ controller }/${ path }`;
  },
  
  postInit: (_body) => {
    return { method: 'POST', body: JSON.stringify(_body), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
  },

  st: {
    clickableTitle: (_color) => { return { color: _color, fontWeight: 'bold', textAlign: 'center'
      , paddingTop: 22, paddingBottom: 22, cursor: 'pointer' }}
  }
}


class MainScreen extends React.Component {

  // constructor(props) {
  //   super(props);
  // }

  state = { val: "", apps: [], entryStamp: undefined };

  componentDidMount() {
    this.get_List();
  }

  frstColWid = 180;

  render() {
    let _st = this.state;
    return (

      <div style={{ display: 'flex', flexDirection: 'row' }} >

        <div style={{ backgroundColor: '#7d4063', width: this.frstColWid }} >

            <div style={{ backgroundColor: '#333', width: this.frstColWid, marginTop: 2 }}>
                <div style={GEN.st.clickableTitle('#ede1e8')} 
                onClick={() => this.props.pa.showColorPicker()} 
                >COLOR PICKER</div>
            </div>

            <div style={{ backgroundColor: '#333', width: this.frstColWid, marginTop: 2 }}>
                <div style={GEN.st.clickableTitle('#ede1e8')} 
                onClick={() => this.props.pa.showDeviceInfoScreen(null, JSON.parse(localStorage.getItem('jsonContent') ?? '[]'))} 
                >JSON EDITOR</div>
            </div>

            { 
              <div style={{ backgroundColor: '#333', width: this.frstColWid, marginTop: 2, marginBottom: 2 }}>
                <div style={GEN.st.clickableTitle('#ede1e8')} 
                onClick={() => this.props.pa.showQRScreen()} 
                >QR DISPLAY</div>
              </div>
            }
            
            { !this.state.globDevs ? null :
              <div style={{ backgroundColor: '#333', width: this.frstColWid, marginTop: 2, marginBottom: 2 }}>
                <div style={GEN.st.clickableTitle('#ede1e8')} 
                onClick={this.show_GlobDevices} 
                >DEVICE REGS</div>
              </div>
            }



            <List topTitle="LOG FOR APPS:" datas={_st.apps} titleProp='app' onItemClick={this.select_App} selectedKey={_st.appName}
                bg='#7d4063' txtCol='#ede1e8' bor='#7d4063' 
                prefixChildren={ <DatePicker className="class-name" style={{ height: 99 }} selected={this.state.selectedDate} onChange={this.handleDateChange} /> }
                />

        </div>


        {!_st.canShowGlobDevices ? null :
          <List topTitle={'DEVICES'} datas={_st.globDevs} titleProp={'dev'} titleRemapedProp={'devRemapped'} onItemClick={this.select_Dev} selectedKey={_st.devName}
            onDoubleClickItem={item => {this.remap_DeviceName(item?.dev)}}
            bg='#6a4487' txtCol='#e4dfe8' bor='#6a4487' />
        }

        {!_st.devs ? null :
          <List topTitle={_st.appName} datas={_st.devs} initWid={333} itemFontSize={13} itemHeight={15}
            titleProp={'dev'} titleRemapedProp={'devRemapped'} onItemClick={this.select_Dev} selectedKey={_st.devName}
            onDoubleClickItem={item => {this.remap_DeviceName(item?.dev)}}
            bg='#6a4487' txtCol='#e4dfe8' bor='#6a4487' />
        }

        {!_st.accs || this.state.hideAccs ? null :
          <List topTitle={_st.devName} datas={_st.accs} titleProp={'acc'} selectedKey={_st.accName} 
            onItemClick={this.select_Acc} onDoubleClickTitle={this.remap_DeviceName}
            bg='#cccccc' txtCol='#222222' bor='#ccc' />
        }

        {!_st.entries ? null :
          <List topTitle={`${ _st.devName} (${ _st.accName })`} datas={_st.entries} initWid={300} 
            titleProp={'file'} onItemClick={this.select_Entry} selectedKey={_st.entryName}
            bg='#aaaaaa' txtCol='#444444' bor='#aaa' />
        }

        <div style={{ backgroundColor: '#eee', flexGrow: 1, height: '100vh' }} >


          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ color: '#555', fontWeight: 'bold', textAlign: 'center', paddingTop: 11, paddingBottom: 11, marginLeft: 22, marginRight: 22 }} >
              {_st.entryName}</div>
            { 
              !(_st.entryStamp) ? null :
              <Moment unix date={_st.entryStamp} format="YYYY/MM/DD - HH:mm" />
            }
          </div>



          <div style={{ color: '#777', fontFamily: 'Roboto Mono', padding: 22 }} >
            {_st.logContent}</div>
          
          <div style={{ color: '#eee', fontFamily: 'Roboto Mono', padding: 22 }} >
            {'.'}</div>

        </div>

        
        {/* <SketchPicker /> */}
      </div>
    );
  }

  get_List = () => {
    this.setState({ ...this.state, apps: [] }, () => {

      fetch('https://api.jvjsc.com:5640/manage/dev_map')
        .then(response => response.json())
        .then(data => {

          glob.dev_map = data;

          
          fetch('https://api.jvjsc.com:5640/manage/dev_model_map')
          .then(response => response.json())
          .then(data => { glob.dev_model_map = data; })
          .catch(err => { console.log('get map err', err) });
          

          fetch('https://api.jvjsc.com:5640/read/list')
            .then(response => response.json())
            .then(data => this.setState({ ...this.state, apps: data }));

          fetch(GEN.url('manage', 'list'), GEN.postInit('ahaeu'))
            .then(response => response.json())
            .then(data => {
              this.setState({ ...this.state, globDevs: data }, () => { 
                this.map_DevicesName(); 
                /**/ // this.select_Dev(this.state.globDevs?.[0]);
                /**/ // this.show_Gl obDevices();
               });
            });

        });

    });
  }

  handleDateChange = date => {
    this.setState({ ...this.state, selectedDate: date }, () => {
      fetch(this.getReadUrl())
      .then(response => response.json())
      .then(data => this.setState({ ...this.state, apps: data }))
      .catch(err => { T.l('handleDateChange', err); this.setState({ ...this.state, apps: [] }) });
    });
  };

  show_GlobDevices = (devFilter, ignoreWarn) => {    
    if (ignoreWarn === true) {
      this.props.pa.showDevListScreen(this.state.globDevs ?? [], devFilter);
    }
    else if (window.confirm('Are you sure you wish to show_Glob Devices?')) {
      //this.onCancel(item)
      this.props.pa.showDevListScreen(this.state.globDevs ?? [], devFilter);
    }
    // this.map_DevicesName();
    // this.setState({ ...this.state, canShowGlobDevices: true, devs: null, accs: null, entries: null, entryStamp: null, logContent: '', entryName: null })
  }

  getDateParam = (sign) => {
    if (!this.state.selectedDate) { return ''; }
    return sign + '=' + moment(this.state.selectedDate).format('YYYY_MM_DD');
  }

  getReadUrl = (app, dev, acc, file) => {
    return `https://api.jvjsc.com:5640/read/list?app=${app??''}&dev=${dev??''}&acc=${acc??''}&file=${file??''}${ this.getDateParam('&') }`;
  }

  select_App = (item) => {
    this.setState({ ...this.state, canShowGlobDevices: false, appName: item.app }, () => {
      fetch(this.getReadUrl(item.app))
        .then(response => response.json())
        .then(data => this.setState({ ...this.state, devs: data, accs: null, entries: null, logContent: '' },
          () => { this.map_DevicesName(); if (this.state.devs?.length === 1) { this.select_Dev(this.state.devs[0]); } }));
    });
  }

  split = (txt) => {
    try {
      let rs = txt?.split('¦');
      return [ rs[0], rs[1] ];
    }
    catch(err) { T.l('split >' + txt + '<' , err); return [ Number.MAX_SAFE_INTEGER, 'could not read entry err: ' + err ] }
  }

  select_Dev = (item) => {
    this.setState({ ...this.state, devName: item.devRemapped ?? item.dev }, () => {
      if (item.isGlob === true) {
        let str = JSON.stringify(item)
        this.setState({ ...this.state, entryName: "DEVICE INFO", logContent: str })      
        fetch(`https://api.jvjsc.com:5640/manage/get?app=${item.app}&dev=${item.dev}`)
          .then(response => response.text())
          .then(txt => {
            let sp = this.split(txt);
            this.setState({ ...this.state, entryStamp: sp[0]  });
            this.props.pa.showDeviceInfoScreen(item.dev, JSON.parse(sp[1]), sp[0]);
          });
      }
      else {
        fetch(this.getReadUrl(item.app, item.dev))
          .then(response => response.json())
          .then(data => this.setState({ ...this.state, hideAccs: data?.length === 1,  accs: data, entries: null, logContent: '' },
            () => { if (this.state.accs?.length === 1) { this.select_Acc(this.state.accs[0]); } }));
      }
    });
  }

  select_Acc = (item) => {
    this.setState({ ...this.state, accName: item.acc }, () => {
      fetch(this.getReadUrl(item.app, item.dev, item.acc))
        .then(response => response.json())
        .then(data => {
          
          data.unshift({ ...item, file: 'dev info' })

          this.setState({ ...this.state, entries: data, logContent: '' }, () => { 
            if (this.state.entries?.length >= 2) { this.select_Entry(this.state.entries[1]); } })
          
        });
    });
  }

  select_Entry = (item) => {
    if (item.file === 'dev info') {
      /**/ this.show_GlobDevices(item.dev, true);
      // let str = JSON.stringify(item)
      // this.setState({ ...this.state, entryName: "DEVICE INFO", logContent: str })      
      // fetch(`https://api.jvjsc.com:5640/manage/get?app=${item.app}&dev=${item.dev}`)
      //   .then(response => response.text())
      //   .then(txt => {
      //     let sp = this.split(txt);
      //     this.setState({ ...this.state, entryStamp: sp[0] });
      //     this.props.pa.showDeviceInfoScreen(item.dev, JSON.parse(sp[1]));
      //   });
    }
    else {
      this.setState({ ...this.state, entryName: item.file }, () => {
        fetch(`https://api.jvjsc.com:5640/read/get?app=${item.app}&dev=${item.dev}&acc=${item.acc}&file=${item.file}${ this.getDateParam('&') }`)
          .then(response => response.text())
          .then(txt => {
            let sp = this.split(txt);
            this.setState({ ...this.state, entryStamp: sp[0] , logContent: sp[1] })
          });
      });
    }
  }

  remap_DeviceName = (devName) => {

    let _find = this.state.devs?.filter(p => p.dev === devName || p.devRemapped === devName)?.[0];
    if (!(_find)) {
      alert('cannot find ' + devName);
    }
    else {      
      T.l('_find', _find);
      const enteredName = prompt('Enter Name to Remap');
      if (!T.isNullOrEmpty(enteredName)) {
        fetch(`https://api.jvjsc.com:5640/manage/remap_dev?from=${ _find.dev }&to=${ enteredName }`)
          .then(response => response.text())
          .then(data => {
            fetch('https://api.jvjsc.com:5640/manage/dev_map')
            .then(response => response.json())
            .then(data => { this.onSuccessfullyRemapDevice({ data: data, enteredName: enteredName }); })
            .catch(err => { console.log('get map err', err) });
          })
          .catch(err => { console.log('remap_dev', err) });
      }
    }
  }

  onSuccessfullyRemapDevice = (data) => {
    glob.dev_map = data.data; this.setState({ ...this.state, devName: data.enteredName }, () => this.map_DevicesName());
  }
  
  map_DevicesName = () => {
    if (!glob.dev_map) { return; }

    let _devs = this.state.devs ?? [];
    _devs.forEach(dev => { let _find = glob.dev_map[dev.dev];  if (!!(_find)) { dev.devRemapped = _find; } });
    
    let _globDevs = this.state.globDevs ?? [];
    _globDevs.forEach(dev => { dev.isGlob = true; let _find = glob.dev_map[dev.dev];  if (!!(_find)) { dev.devRemapped = _find; } });
    
    
    this.setState({ ...this.state, dev: _devs, globDevs: _globDevs });
  }




}


//const headers = { mode: "no-cors", cache: "no-store" }




// ====================================================================================================================================================================================================================================================================================================================================================================================================================
// ==============================================================   ========   =======        =====            ============================================================================================================================================================================================================================================================================================================
// ==============================================================   ========   =======   ==============   ==============================================================================================================================================================================================================================================================================================================
// ==============================================================   ========   =======        =========   ==============================================================================================================================================================================================================================================================================================================
// ==============================================================   ========   ============   =========   ==============================================================================================================================================================================================================================================================================================================
// ==============================================================       ====   =======        =========   =============================================================================================================================================================================================================================================================================================================
// ====================================================================================================================================================================================================================================================================================================================================================================================================================
class List extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }
  state = { appWid: this.props.initWid ?? 150 }
  render() {
    //var rect = ReactDOM.findDOMNode(this)?.getBoundingClientRect();
    let _pr = this.props;
    return (
      <div ref={this.wrapper} style={{ borderRight: `2px solid ${_pr.bor}`, width: this.state.appWid, backgroundColor: _pr.bg, display: 'flex', flexDirection: 'row' }} >
        <div style={{ width: this.state.appWid, borderRightWidth: 2, borderRightColor: _pr.bor }} >

          {this.props.prefixChildren}

          <div onDoubleClick={() => { _pr.onDoubleClickTitle(_pr.topTitle); }} style={GEN.st.clickableTitle(_pr.txtCol)}
               onDrag={(a) => { this.setWid(a.screenX) }} draggable >
            {_pr.topTitle}</div>


          {_pr.datas.sort(T.sort_by('lu', true, (p) => p)).map((it, k) =>
            <ListItem item={it} bg={_pr.bg} txtCol={T.mutColor(_pr.txtCol, -15)} selectedKey={_pr.selectedKey} key={k}
              title={it[_pr.titleProp]} titleRemaped={ !_pr.titleRemapedProp ? '' : it[_pr.titleRemapedProp] } 
              onClick={item => { _pr.onItemClick(item); }}  lu={it.lu} itemFontSize={this.props.itemFontSize} itemHeight={this.props.itemHeight}
              onDoubleClick={item => { _pr.onDoubleClickItem(item); }}  
              />)
          }
        </div>
      </div>
    );
  }

  setWid(x) {
    var rect = this.wrapper.current.getBoundingClientRect();
    //console.log(x, ' -= ', rect.x, rect);
    x -= rect.x;
    if (x < 50) return;
    this.setState({ ...this.state, appWid: x });
  }
}

class ListItem extends React.Component {
  // constructor(props) { super(props); } 
  itemCallback = (_paTitle) => {
    console.log("clicked on Item 1 " + +new Date() + ' | ' + this.props.title + ' / ' + _paTitle);
  };


  render() {
    let _pr = this.props;
    let _selected = !!(_pr.selectedKey) && (_pr.title === _pr.selectedKey || _pr.titleRemaped === _pr.selectedKey);
    return (
        <div style={{
          color: _pr.txtCol, width: '100%', height: this.props.itemHeight, background: T.mutColor(_pr.bg, _selected ? 30 : 15), paddingLeft: _selected ? 5 : 33, paddingTop: 11, paddingBottom: 11, cursor: 'pointer'
          , borderLeft: _selected ? '' : `5px solid ${T.mutColor(_pr.bg, 20)}`
          , fontSize: this.props.itemFontSize
        }}
          onClick={() => { _pr.onClick(_pr.item); }} 
          onDoubleClick={() => { _pr.onDoubleClick(_pr.item); }} 
          >
          { /*(_pr.lu - 1586100000) + ' | ' +*/ (T.isNullOrEmpty(_pr.titleRemaped) ? _pr.title : _pr.titleRemaped) }

        </div>
    );
  }

  handleClick = () => {
    console.log(`Clicked on menu ${''}`);
  };

}

export default MainScreen;