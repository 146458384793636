import React from 'react';
import T from './../T';
import Moment from 'react-moment';
import 'moment-timezone';
import { SketchPicker, PhotoshopPicker, SwatchesPicker } from 'react-color';
//import './ColorPicker.css';

const GEN = {  
    st: {
      clickableTitle: (_color, bg) => { return { color: _color, backgroundColor: bg, fontWeight: 'bold', textAlign: 'center', paddingTop: 11, paddingBottom: 11, paddingLeft: 22, paddingRight: 22, cursor: 'pointer' }}
    }
  }

class ColorPicker extends React.Component {
    constructor(props) {
      super(props);
      this.wrapper = React.createRef();
    }
    
    state={        
        col: 'red'
    }

    render() {

        T.l('this.props.enabled', this.props.enabled)
        return(
            !this.props.enabled ? null :

            <div style={{ width: '100vw', height: '100vh', backgroundColor: '#00000099', position: 'absolute', alignItems: 'flex-start'
                        //, display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'normal' 
                        }} 
                onClick={() => { this.props.pa.toogleColorPicker(); }} >            




<div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', alignSelf: 'left', backgroundColor: 'white', borderRadius: 22, padding: 22 }} onClick={(e) => { e.stopPropagation(); }} >
                
                
                <PhotoshopPicker  
                    
        color={ this.state.col }
        onChange={ this.handleChangeComplete }
        
        />

        <SwatchesPicker 
        height='100%'
        color={ this.state.col }
        onChange={ this.handleChangeComplete }
        
        />
                </div>
            

      
            
            </div>
            
        );
    }

    handleChangeComplete = (color) => {
        this.setState({ col: color.hex });
      };

}


export default ColorPicker;
