import APITools from './../Operators/APITools';
import T from "./../T";

const aha_api_requestInit = () => { return { method: 'GET', headers: { 'Content-type': 'application/json', 'User-Agent': "AHA Cafe/Android" } }; }

const CACHE_KEY = 'pos.customers.cache';
const loadCache = () => {
    return JSON.parse(localStorage.getItem(CACHE_KEY) ?? '{}');
}

export default class aha_APICalls {

    static _pcc = null;
    static posCustomersCache = () => {
        if (aha_APICalls._pcc == null) {
            T.l('loadCache');
            aha_APICalls._pcc = loadCache();
        }
        return aha_APICalls._pcc;
    }
    static writeCache = () => {
        //localStorage.setItem(CACHE_KEY, JSON.stringify(aha_APICalls._pcc));
    }

    static get = (_deviceId) => {
        return new Promise((resolve, reject) => {

            let cache = aha_APICalls.posCustomersCache();
            let find = cache[_deviceId];


            if (!!(find) && ((+new Date() - find.timeGot) < 3600000 )) {
                resolve(find);
            }
            else {

                let url = 'https://api.ahacafe.vn/pos.customers.get';
                let _bodyObj = { DeviceID: _deviceId, Histories: false };
                
                fetch (APITools.convert_shark_get(url, _bodyObj), { ...aha_api_requestInit() })
                .then(res => res.text())
                .then(txt => {
                    if (T.isNullOrEmpty(txt)) {
                        T.l("BLANK", _deviceId);
                        resolve({ ID: -1 });
                    }
                    else {
                        let obj = JSON.parse(txt); obj.timeGot = +new Date();
                        resolve(obj);
                        cache[_deviceId] = obj;
                        aha_APICalls.writeCache();
                    }
                 })
                .catch(err => { T.l("err 24 for " + _deviceId, err); resolve({ ID: -1 }); });

            }


        });
    }

    static getVers = (_deviceId) => {
        return new Promise((resolve, reject) => {
            
            fetch ('https://api.ahacafe.vn/wallet/vers_ios.txt', { ...aha_api_requestInit() })
            .then(res => res.json())
            .then(_ios => {

                fetch ('https://api.ahacafe.vn/wallet/vers_adr.txt', { ...aha_api_requestInit() })
                .then(res => res.json())
                .then(_adr => {
                    
                    resolve({ adr: _adr, ios: _ios });
                    
                 })
                .catch(err => { resolve({ adr: 0, ios: 0 }); });
                
             })
            .catch(err => { resolve({ adr: 0, ios: 0 }); });

        });
    }

}