/* eslint-disable no-loop-func */
/* eslint-disable no-whitespace-before-property */
import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import T from './../../T';
//import { JsonEditor as Editor } from 'jsoneditor-react';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment/min/moment-with-locales';
import qrcode from 'qrcode-terminal';

import aha_APICalls from './../../APICalls/aha_APICalls';
import jv_APICalls from './../../APICalls/jv_APICalls'; 
import glob from './../../glob';
import './QRScreen.css';

const GEN = {  
    st: {
      clickableTitle: (_color, bg, _fw) => { return { color: _color, backgroundColor: bg, fontWeight: _fw ?? 'bold', textAlign: 'center'
        , marginRight: 11, paddingTop: 11, paddingBottom: 11, paddingLeft: 22, paddingRight: 22, cursor: 'pointer' }}
    }
  }

export default class QRScreen extends React.Component {

    constructor(props) {
        super(props);
        let _data = JSON.parse(localStorage.getItem('pastQRs'));
        if (!(_data) || _data.length == 0) { _data = []; _data.push("qrStr"); }
        this.state= {
            data: _data,
        }
    }
    componentDidMount() {
        try {
            qrcode.generate( this.state.data?.[0] ?? "str" , { small: true }, qr => {
                this.showQRString(qr);
            });
        }
        catch(e) { T.l('qr err.', e); }
    }

    showQRString = (qr) => {
        return new Promise((resolve) => {
            try {
                let qrs = qr.split('\n');
                if (!qrs) {
                    qrs = [];
                }
                
                let qrrs = [];
                for (let i = 0; i < 10; i++) { qrrs.push(' '); }
                qrs.forEach(q => { qrrs.push(q); });
                for (let i = 0; i < 10; i++) { qrrs.push(' '); }
    
                this.setState({ ...this.state, qrs: qrrs }, () => { resolve(); });
            }
            catch(e) { T.l('qr err 2', e); }
        })
    }

    render() {

        let _data = this.state.data;

        return(
            <div style={{ width: '100vw', height: '100vh', backgroundColor: '#eee'
                        , display: 'flex', flexDirection: 'column' }} >

                {   /* TITLE BAR */
                    <div style={{ width: '100vw', height: '5vh', backgroundColor: '#555', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '100vw', display: 'flex', flexDirection: 'row' }}>
                            <div style={GEN.st.clickableTitle('#ccc', '#444')} onClick={() => this.props.pa.closeQRScreen()} >
                                {'✖️'}</div>

                            <div style={{ alignSelf: 'center', color: 'white' }} >
                                {this.title}
                            </div>

                            <div style={{ flexGrow: 1 }} />
                        </div>
                    </div>
                }

                <div className={"horzPa"} style={{ width: '100vw', height: '100vh' }} >
                    <div className={"listPa"}>
                        {   _data?.map((it, k) => { return(
                                <ListItem item={it} bg={'red'} txtCol={'white'} selectedKey={this.selectedKey} key={k}
                                    title={it}
                                    onClick={(item, key) => { this.onItemClick(item, key); }}  itemFontSize={13} itemHeight={15}
                                    onDoubleClick={item => { this.onDoubleClickItem(item); }}  
                                    />
                            ); }) }
                    </div>
                    <div className={"vertPa"}>

                        <form className="form-horizontal" style={{ marginRight: 11, display: 'flex', backgroundColor: 'black', flexDirection: 'row', alignItems: 'center' }} >
                            <input type="text" className="form-control"
                                style={{ marginRight: 11, height: '5vh', textAlign: 'center', backgroundColor: 'black', color: 'white', borderColor: 'black' }}
                                value={this.state.qrInput} onChange={this.updateQRInput} />
                            <div style={GEN.st.clickableTitle('#ccc', '#444', 500)} onClick={() => this.genQRAndDisplay()} >
                                {'GEN QR'}</div>
                        </form>

                        <div className={"qrPa"}>
                            <div className={"qrPa2"}>
                            {   this.state.qrs?.map((p, k) => { return (
                                    <div className={"qrLine"} >{p}</div>
                                    ); }) }
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
    onItemClick = (item, key) => { T.l('onItemClick', item, key); this.selectedKey = key; this.forceUpdate(); this.setState({ ...this.state, qrInput: item }, () => { this.genQRAndDisplay(); });  }
    onDoubleClickItem = (item) => { }

    
    updateQRInput = (e) => {        
        this.setState({ ...this.state, qrInput: e.target.value });
    }
    genQRAndDisplay = () => {        
        try {
            qrcode.generate( this.state.qrInput , { small: true }, qr => {
                this.showQRString(qr).then(() => {

                    if (!this.state.data.includes(this.state.qrInput)) {
                        this.state.data.unshift(this.state.qrInput);
                        localStorage.setItem("pastQRs", JSON.stringify(this.state.data));
                        this.setState({ ...this.state, data: this.state.data });
                    }
                    
                });
            });
    
        }
        catch(e) { T.l('qr err 3', this.state.qrInput + ' / ' + e); }
    }
}

class ListItem extends React.Component {
    // constructor(props) { super(props); } 
    itemCallback = (_paTitle) => {
      console.log("clicked on Item 1 " + +new Date() + ' | ' + this.props.title + ' / ' + _paTitle);
    };
  
  
    render() {
      let _pr = this.props;
      let _selected = !!(_pr.selectedKey) && (_pr.title === _pr.selectedKey);
      return (
          <div style={{
            color: _pr.txtCol, width: '100%', height: this.props.itemHeight, background: T.mutColor(_pr.bg, _selected ? 30 : 15), paddingLeft: _selected ? 5 : 33, paddingTop: 11, paddingBottom: 11, cursor: 'pointer'
            , borderLeft: _selected ? '' : `5px solid ${T.mutColor(_pr.bg, 20)}`
            , fontSize: this.props.itemFontSize
          }}
            onClick={() => { _pr.onClick(_pr.item, this.key); }} 
            onDoubleClick={() => { _pr.onDoubleClick(_pr.item); }} 
            >
            { _pr.title?.substring(0, 22) }
  
          </div>
      );
    }
  
    handleClick = () => {
      console.log(`Clicked on menu ${''}`);
    };
  
  }