/* eslint-disable no-loop-func */
/* eslint-disable no-whitespace-before-property */
import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import T from './../../T';
//import { JsonEditor as Editor } from 'jsoneditor-react';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment/min/moment-with-locales';
//import NotificationSender from './NotificationSender';
import DataTable, { useTableContext , getNumberOfPages, detectRTL, Select, Pagination  } from 'react-data-table-component';
import aha_APICalls from './../../APICalls/aha_APICalls';
import jv_APICalls from './../../APICalls/jv_APICalls'; 
import glob from './../../glob';
import './DevListScreen.css';
import NotificationSender from './../DeviceInfoScreen/NotificationSender';


//const StyledDataTable = styled(DataTable)`
//    `

const GEN = {  
    st: {
      clickableTitle: (_color, bg, _fw) => { return { color: _color, backgroundColor: bg, fontWeight: _fw ?? 'bold', textAlign: 'center'
        , marginRight: 11, paddingTop: 11, paddingBottom: 11, paddingLeft: 22, paddingRight: 22, cursor: 'pointer' }}
    }
  }

const PAGEC = 24;
//const PAGEC = 14;
//const PAGEC = 99;

class DevListScreen extends React.Component {

    constructor(props) {
        super(props);
      
        this.state= {
            data: [] //this.mapData(this.props.initDevList)
            //, modelNameToRemapTo: '', nameToRemapTo: '', nameToRemapTo2: ''
        }  
        //this.reffer = React.createRef();
    }

    componentDidMount() {

        aha_APICalls.getVers().then(reqVers => {


            let datas = [ ...this.props.initDevList];

            if (!!(z.nx(this.props.devListFilter))) {
                datas = datas.filter(p => p.dev == this.props.devListFilter);
            }

            //datas = datas.slice(0, 20);


            let page = 1; let totalRows = datas?.length;
            let _fr = (page - 1) * PAGEC; 
            //let _to = _fr + PAGEC; if (_to >= totalRows) { _to = totalRows; }
            let _to = totalRows;
    
            let step = 1; 
            
            for (let i = _fr; i < totalRows; i++) {

                const item = datas[i];
                item.reqVers = reqVers;

                if (i < _to) {
                    if (!(item)) {
                        T.l('!item', i); step++;
                    }
                    else {
                        if (!!item.sync) { this.setData(step++, _to, datas); } 
                        else { 
                            /**///this.find_item_sync(item, () => { this.setData(step++, _to, datas); }); 
                            this.setData(step++, _to, datas);
                        }
                    }
                }
            }



        })

        
    }

    find_item_sync = (item, _doThen) => {        

        aha_APICalls.get(item.dev) 
        .then(res => { 

            item.sync = res; 
            item.WID = res?.ID; 
            
            jv_APICalls.manage_get(item.app, item.dev)
            .then(DS => {
                item.DS = DS;
                item.tag = keyDicShort[item?.DS?.codePushMetas?.RUNNING?.dk] ?? '';
                item.SysVer = z.toNum(item?.DS?.DevInfo?.getSystemVersion);
                item.BinVer = z.toNum(item?.DS?.DevInfo?.getVersion);
                item.RunVer = z.toNum(item?.DS?.codePushMetas?.RUNNING?.description);
                item.Carrier = item?.DS?.DevInfo?.DevInfoAsync?.getCarrier;
                if (!!(_doThen)) {
                    _doThen();
                }
            });
        });
    }



    setData = (_fr, _to, datas) => {
        if (_fr === _to) {
            this.setState({ ...this.state, data: datas })
        }
    }
    
    mapData = (list) => {
        //T.l('list', list);
// VD: app: "ahaeu" acc: null dev: "897472056CEB487BAE9401DE0E739F55"  lu: 1586573848 isGlob: true devRemapped: "Hoàng long ◾ 🍎 ◾ iPhone 6s" tag: null msg: null time: null file: null
        //return [...list];
        return list.sort(T.sort_by('lu', true, (p) => p))
    }

    reset = () => {
         //T.l('reset', this.props.devListFilter);
        // setTimeout(() => this.onChangePage(1, this.state.data.length), 500);
    }

    render() {

        // const columnz = [
        //     { grow: 0.3, name: 'LU' , format: r => moment(new Date(r.lu * 1000)).format('DD/MM-HH:mm')                                                                , selector: 'lu', sortable: true, compact: true }
        //   , { grow: 1, name: 'DevId' , selector: 'dev', sortable: true }
        //   , { grow: 18, name: 'Wallet    |    Name   |   Address' 
        //       , format: r => z.combine([4,15, 2, 5], [r?.sync?.ID?.toString()
        //                           , r?.sync?.Name , (!!(glob.dev_map?.[r.dev]) ? '☑️' : '') , z.find_private(r)   ]
        //                           , ' | ', ' ')    
        //       , style:{ fontFamily: 'Roboto Mono' } , selector: 'WID', sortable: true }
        //   , { grow: 19, name: 'Capabilities | Brand  |  Model  |  DevName' 
        //       , format: r => z.combine([5,4,10,14], [z.find_capab_str(r), r?.DS?.DevInfo?.getBrand?.toUpperCase()
        //               , z.mapModel(r?.DS?.DevInfo?.getModel) , r?.DS?.DevInfo?.DevInfoAsync?.getDeviceName]
        //                           , ' | ', ' ')    
        //       , style:{ fontFamily: 'Roboto Mono' } , selector: 'DS', sortable: false }
        //   , { grow: 1, name: 'SysVer' , format: r => (r?.DS?.isIOS ? '🔵' : '🟩') + ' ' + r?.DS?.DevInfo?.getSystemVersion , selector: 'SysVer', sortable: true }
        //   , { grow: 1, name: 'BINARY' , format: r => z.find_BINARY_str(r) , selector: 'BinVer', sortable: true }
        //   , { grow: 1, name: 'RUNNING' , format: r => z.find_RUNNING_str(r) , selector: 'RunVer', sortable: true }
        //   , { grow: 22, name: 'Carrier   |   Device   |   DeviceId   |   Hardw   |   IP' 
        //           , format: r => z.combine([5,9,13,4,15]
        //               , [z.nu(r.Carrier), z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getDevice), r?.DS?.DevInfo?.getDeviceId 
        //                   , z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getHardware), z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getIpAddress)]
        //                               , ' | ', ' ')    
        //           , style:{ fontFamily: 'Roboto Mono' } , selector: 'Carrier', sortable: true }
        //   , { grow: 8 , name: 'PushToken   |   InstallReferrer   |   Fingerprint' 
        //           , format: r => z.combine([2,2,2]
        //               , [(z.nu(r?.DS?.pushToken)?.toUpperCase() ?? ''), z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getInstallReferrer)?.toUpperCase() 
        //                   , z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getFingerprint)?.toUpperCase()]
        //                               , ' | ', ' ')    
        //           , style:{ fontFamily: 'Roboto Mono' } , selector: 'DS', sortable: false }
        //   ];

        //const _data = [ ...this.state.data ];
        const columnz = [ ...columns ];

        return(            
            
            !this.props.enabled ? null :
            <div style={{ width: '100vw', height: '100vh', backgroundColor: '#eee'
                        , display: 'flex', flexDirection: 'column' }} >

                {   /* TITLE BAR */
                    <div style={{ width: '100vw', height: '5vh', backgroundColor: '#555', display: 'flex', flexDirection: 'row' }}>
                        <div style={{width: '100vw', display: 'flex', flexDirection: 'row'}}>
                            <div style={GEN.st.clickableTitle('#ccc', '#444')} onClick={() => this.props.pa.closeDevListScreen() } >
                                {'✖️'}</div>
                            <div style={GEN.st.clickableTitle('#ccc', '#444')} onClick={() => this.toogleNotificationSender() } >
                                {'🚀'}</div>
                            


                            {/* <div style={{ color: 'white', backgroundColor: 'black', textAlign: 'center'
                                , marginRight: 11, paddingTop: 11, paddingBottom: 11, paddingLeft: 22, paddingRight: 22 }}>
                                {this.state.modelToRemap}</div> */}
                            
                            <form className="form-horizontal" style={{ marginRight: 11, display: 'flex', backgroundColor: 'black', flexDirection: 'row', alignItems: 'center' }} >
                                <input type="text" className="form-control" 
                                    style={{ marginRight: 11, height: '5vh', textAlign: 'center', backgroundColor: 'black', color: 'white', borderColor: 'black' }}
                                    value={this.state.modelNameToRemapTo} onChange={this.updateModelNameToRemapTo} />
                            </form>

                            <div style={GEN.st.clickableTitle('#ccc', '#444', 500)} onClick={() => this.search() } >
                                {'search ' + this.state.modelToRemap }</div>
                            <div style={GEN.st.clickableTitle('#ccc', '#444', 500)} onClick={() => this.remapModel() } >
                                {'map model'}</div>


                            
                            

                            
                            <form className="form-horizontal" style={{ marginLeft: 22, marginRight: 11, display: 'flex', backgroundColor: 'black', flexDirection: 'row', alignItems: 'center' }} >
                                <input type="text" className="form-control" 
                                    style={{ marginRight: 11, height: '5vh', width: '15vw', textAlign: 'center', backgroundColor: 'black', color: 'white', borderColor: 'black' }}
                                    value={this.state.nameToRemapTo} onChange={this.updateNameToRemapTo} />
                            </form>
                            <div style={GEN.st.clickableTitle('#ccc', '#444', 500)} onClick={() => this.remapName(this.state.nameToRemapTo) } >
                                {'map new name'}</div>


                            <form className="form-horizontal" style={{ marginLeft: 22, marginRight: 11, display: 'flex', backgroundColor: 'black', flexDirection: 'row', alignItems: 'center' }} >
                                <input type="text" className="form-control" 
                                    style={{ marginRight: 11, height: '5vh', width: '15vw', textAlign: 'center', backgroundColor: 'black', color: 'white', borderColor: 'black' }}
                                    value={this.state.nameToRemapTo2} onChange={this.updateNameToRemapTo2} />
                            </form>
                            <div style={GEN.st.clickableTitle('#ccc', '#444', 500)} onClick={() => this.remapName(this.state.nameToRemapTo2) } >
                                {'update name'}</div>

                            <div style={GEN.st.clickableTitle('#ccc', '#444', 500)} onClick={() => this.onRowDoubleClicked(this.lastRowClicked) } >
                                {'detail'}</div>




                            
                            <div style={{ alignSelf: 'center', color: 'white' }} >
                                {this.title}
                            </div>
                            
                            <div style={{ flexGrow: 1 }} />
                            <Moment unix style={{ color: '#ddd', fontWeight: 'bold' }}
                                date={this.props.jsonStamp} format="YYYY/MM/DD - HH:mm" />
                        </div>
                    </div>
                }



{ // VD: app: "ahaeu" acc: null dev: "897..."  lu: 158.. isGlob: true devRemapped: "Hoàng long ◾ 🍎 ◾ iPhone 6s" tag: null msg: null time: null file: null
                    <div style={{ width: '100vw', height: '95vh' }} >                    
                        <DataTable noHeader pagination highlightOnHover pointerOnHover striped dense theme="light" 
                            columns={columnz}
                            data={this.state.data} keyField={'dev'} paginationPerPage={PAGEC}
                            //selectableRows selectableRowsHighlight selectableRowSelected={row => { return row.isSelected; }}
                            selectableRowsHighlight
                            onSelectedRowsChange={this.updateState}
                            style={{ width: '100vw', alignSelf: 'flex-start' }} onChangePage={this.onChangePage} 
                            onRowClicked={this.onRowClicked}
                            onRowDoubleClicked={this.onRowDoubleClicked}
                            //ref={this.reffer}
                            />
                    </div>
                }
                {   /* BOTTOM BAR */
                    // <div style={{ width: '100vw', height: '5vh', backgroundColor: '#555', display: 'flex', flexDirection: 'row' }}>
                    //     <div style={{width: '100vw', display: 'flex', flexDirection: 'row'}}>
                    //         <div style={GEN.st.clickableTitle('#ccc', '#444')} onClick={() => this.props.pa.closeDevListScreen() } >
                    //             {'✖️'}</div>
                    //     </div>
                    // </div>
                }

                {   /* NOTIFICATION SENDER */
                    <NotificationSender pa={this} 
                        enabled={this.state.canShowNotificationSender} 
                        closeNotificationSender={() => { this.toogleNotificationSender() }} 
                        pushToken={this.lastRowClicked?.DS?.pushToken} />
                }

            </div>
            
        );
    }

    updateState = (state, doThen) => {
        this.setState({ ...this.state, selectedRows: state.selectedRows }, () => { if (doThen != null) { doThen(); } });
    }

    curPage = 1; totalRows = 0; step = 1;
    onChangePage = (_page, _totalRows) => {
        this.curPage = _page; this.totalRows = _totalRows;
        let _fr = (this.curPage  - 1) * PAGEC; let _to = _fr + PAGEC; if (_to >= this.totalRows) { _to = this.totalRows; }
        this.step = 1;
        for (let i = _fr; i < _to; i++) {
            const item = this.state.data[i];
            if (!item.sync) { 
                this.find_item_sync(item, () => { if ((this.step++) ===  (_to - _fr - 1)) { this.updateState({selectedRows: []}); } });
            }
            else { if ((this.step++) ===  (_to - _fr - 1)) { this.updateState({selectedRows: []}); } }
        }        
    }

    toogleNotificationSender = () => {
        this.setState({ ...this.state, canShowNotificationSender: !this.state.canShowNotificationSender })
    }

    onRowDoubleClicked = (row) => {
        T.l('onRowDoubleClicked = (row) => {', row.dev);
        this.props.pa.showDeviceInfoScreen(row.dev, row, 0);
    }
    
    lastRowClicked = null;
    onRowClicked = (r) => {
        if (r == null) { return; } this.lastRowClicked = r;
      r.isSelected = true;
      let _di = r?.DS?.DevInfo;

        let _mntrt = _di?.DevInfoAsync?.getDeviceName;        
        if (_mntrt?.substring(0, 6) === 'iPhone') { _mntrt =  'iP ' + _mntrt.substring(6, 99) }
        else if (_mntrt?.substring(0, 6) === 'Galaxy') { _mntrt =  '' + _mntrt.substring(6, 99) }
        else if (_mntrt?.substring(0, 6) === 'HUAWEI') { _mntrt =  '' + _mntrt.substring(6, 99) }
        else if (_mntrt?.substring(0, 4) === 'OPPO') { _mntrt =  '' + _mntrt.substring(4, 99) }

      this.setState({ ...this.state, modelToRemap: _di?.getModel, devIdToRemap: r.dev, modelNameToRemapTo: _mntrt }, () => {
        
        let _n = `${ r.tag }${  z.nx(r?.sync?.Name) ?? r?.sync?.ID?.toString()  }`
        + (r?.DS?.isIOS ? '' : ` | ${ r?.DS?.DevInfo?.getBrand?.toUpperCase()?.substring(0, 4) }`)
        + ` | ${ z.mapModel(r?.DS?.DevInfo?.getModel)?.substring(0, 10) }`;
        this.setState({ ...this.state, nameToRemapTo: _n, nameToRemapTo2: glob.dev_map?.[r.dev] ?? '' });
        navigator.clipboard.writeText(_di?.DevInfoAsync?.getDeviceName);
      });

      /**/
      this.find_item_sync(r, () => { 
        this.onChangePage(this.curPage, this.totalRows); 
        this.updateState({selectedRows: []}, () => { });
       });
    }
    updateModelNameToRemapTo = (e) => {        
        this.setState({ ...this.state, modelNameToRemapTo: e.target.value });
    }
    search = () => {
        window.open('https://www.google.com/search?q=' + this.state.modelToRemap);
    }
    remapModel = () => {
        if (!T.isNullOrEmpty(this.state.modelNameToRemapTo) && !T.isNullOrEmpty(this.state.modelToRemap)) {
        fetch(`https://api.jvjsc.com:5640/manage/remap_dev_model?from=${ encodeURIComponent(this.state.modelToRemap) }&to=${ encodeURIComponent(this.state.modelNameToRemapTo) }`)
            .then(response => response.text())
            .then(data => {
                fetch('https://api.jvjsc.com:5640/manage/dev_model_map')
                .then(response => response.json())
                .then(data => { 
                    glob.dev_model_map = data; 
                    this.onChangePage(this.curPage, this.totalRows); 
                    this.updateState({selectedRows: []}, () => { 
                        this.onRowClicked(this.lastRowClicked);
                     });
                })
                .catch(err => { console.log('get map err', err) });
            })
            .catch(err => { console.log('remap_dev', err) });
        }
    };

    updateNameToRemapTo = (e) => {        
        this.setState({ ...this.state, nameToRemapTo: e.target.value });
    }
    updateNameToRemapTo2 = (e) => {        
        this.setState({ ...this.state, nameToRemapTo2: e.target.value });
    }
    remapName = (_nameToRemapTo) => {
        if (!T.isNullOrEmpty(_nameToRemapTo) && !T.isNullOrEmpty(this.state.devIdToRemap)) {
            fetch(`https://api.jvjsc.com:5640/manage/remap_dev?from=${ encodeURIComponent(this.state.devIdToRemap) }&to=${ encodeURIComponent(_nameToRemapTo) }`)
                .then(response => response.text())
                .then(data => {
                    fetch('https://api.jvjsc.com:5640/manage/dev_map')
                    .then(response => response.json())
                    .then(data => { 
                        glob.dev_map = data; 
                        this.props.onSuccessfullyRemapDevice({ data: data, enteredName: _nameToRemapTo }); 
                        this.updateState({selectedRows: []});
                    })
                    .catch(err => { console.log('get map err', err) });
                })
                .catch(err => { console.log('remap_dev', err) });
            }
    };
}

export default DevListScreen;

// VD {"Address":"","Addresses":[],"Completed":0,"Completion":0,"DeliveryState":"None","DeviceID":"3D76..","Discount":0.07,"Email":"","Histories":null,"ID":4275,"Level":0,"MaxBudget":2000,"MinDeliverableTotal":50000,"Mobile":"0966936938"
//,"Name":"Zalo Tester","PointValue":1000,"Points":0,"Protected":false,"Requirement":30000,"Rewards":0,"RewardsLastingDays":88.71099225357986,"Spent":0.000000}
const columns = [
  //{ grow: 1, name: 'DevId'                                                                                                                                  , selector: 'dev', sortable: true, compact: true }
  { grow: 0.3, name: 'LU' , format: r => moment(new Date(r.lu * 1000)).format('DD/MM-HH:mm')                                                                , selector: 'lu', sortable: true, compact: true }

, { grow: 1, name: 'DevId' , selector: 'dev', sortable: true }

, { grow: 18, name: 'Wallet    |    Name   |   Mapped  |  P' 
    , format: r => z.combine([4, 2, 14, 2, 5], [r?.sync?.ID?.toString()
                        , (!!(glob.dev_map?.[r.dev]) ? '☑️' : '') 
                        , r?.sync?.Name 
                        , z.find_mapped(r) , z.find_private(r)   ]
                        , ' | ', ' ')    
    , style:{ fontFamily: 'Roboto Mono' } , selector: 'WID', sortable: true }

// , { grow: 0.5, name: 'capab' , format: r => z.find_capab_str(r)                                                                                 , selector: 'DS', sortable: false }


, { grow: 19, name: 'Capabilities | Brand  |  Model  |  DevName' 
    , format: r => z.combine([5,4,10,14], [z.find_capab_str(r), r?.DS?.DevInfo?.getBrand?.toUpperCase()
            , z.mapModel(r?.DS?.DevInfo?.getModel) , r?.DS?.DevInfo?.DevInfoAsync?.getDeviceName]
                        , ' | ', ' ')    
    , style:{ fontFamily: 'Roboto Mono' } , selector: 'DS', sortable: false }


// , { grow: 8, name: 'BINARY  |  RUNNING' 
//         , format: r => z.combine([8,13], [z.find_BINARY_str(r), z.find_RUNNING_str(r)]
//                             , ' | ', ' ')    
//         , style:{ fontFamily: 'Roboto Mono' } , selector: 'DS', sortable: false }
        
        //, { grow: 9, name: 'FirstInstall' , format: r => moment(new Date(r?.DS?.DevInfo?.DevInfoAsync?.getFirstInstallTime)).format('DD/MM/YYYY - HH:mm')                                                         , selector: 'lu', sortable: true, compact: true }
//, { grow: 1, name: 'Wallet' , format: r => r?.sync?.ID                                                                                                       , selector: 'sync', sortable: false, compact: true }
//, { grow: 1, name: 'Name' , format: r => r?.sync?.Name                                                                                                       , selector: 'sync', sortable: false, compact: true }
//, { grow: 1, name: 'Brand' , format: r => r?.DS?.DevInfo?.getBrand?.substring(0, 4)?.toUpperCase()                                                      , selector: 'DS', sortable: false }
//, { grow: 1, name: 'Manufacturer' , format: r => r?.DS?.DevInfo?.DevInfoAsync?.getManufacturer?.substring(0, 4)?.toUpperCase()                                                      , selector: 'DS', sortable: false }
// , { grow: 1, name: 'Model' , format: r => r?.DS?.DevInfo?.getModel                                                                                      , selector: 'DS', sortable: false }
// , { grow: 3, name: 'DeviceName' , format: r => r?.DS?.DevInfo?.DevInfoAsync?.getDeviceName                                                                                 , selector: 'DS', sortable: false }




//, { grow: 0.3, name: 'Sys' , format: r => r?.DS?.DevInfo?.getSystemVersion                                                                                      , selector: 'DS', sortable: false }
, { grow: 1, name: 'SysVer' , format: r => (r?.DS?.isIOS ? '🔵' : '🟩') + ' ' + r?.DS?.DevInfo?.getSystemVersion , selector: 'SysVer', sortable: true }



, { grow: 1, name: 'BINARY' , format: r => z.find_BINARY_str(r) , selector: 'BinVer', sortable: true }
, { grow: 1, name: 'RUNNING' , format: r => z.find_RUNNING_str(r) , selector: 'RunVer', sortable: true }


// , { grow: 3, name: 'Carrier' , format: r => r?.DS?.DevInfo?.DevInfoAsync?.getCarrier                                                                                 , selector: 'DS', sortable: false }



, { grow: 22, name: 'Carrier   |   Device   |   DeviceId   |   Hardw   |   IP' 
        , format: r => z.combine([5,9,13,4,15]
            , [z.nu(r.Carrier), z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getDevice), r?.DS?.DevInfo?.getDeviceId 
                , z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getHardware), z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getIpAddress)]
                            , ' | ', ' ')    
        , style:{ fontFamily: 'Roboto Mono' } , selector: 'Carrier', sortable: true }

// , { grow: 3, name: 'Device' , format: r => z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getDevice)                                                                                         , selector: 'DS', sortable: false }
// , { grow: 3, name: 'DeviceId' , format: r => r?.DS?.DevInfo?.getDeviceId                                                                                 , selector: 'DS', sortable: false }
// , { grow: 3, name: 'Hardw' , format: r => z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getHardware, 5)                                                                                         , selector: 'DS', sortable: false }




// , { grow: 3, name: 'Ip' , format: r => z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getIpAddress)                                                                                 , selector: 'DS', sortable: false }

, { grow: 8 , name: 'PushToken   |   InstallReferrer   |   Fingerprint' 
        , format: r => z.combine([2,2,2]
            , [(z.nu(r?.DS?.pushToken)?.toUpperCase() ?? ''), z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getInstallReferrer)?.toUpperCase() 
                , z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getFingerprint)?.toUpperCase()]
                            , ' | ', ' ')    
        , style:{ fontFamily: 'Roboto Mono' } , selector: 'DS', sortable: false }

// , { grow: 1, name: 'PushToken' , format: r => z.nu(r?.DS?.pushToken, 2)?.toUpperCase()                                                                            , selector: 'DS', sortable: false }
// , { grow: 1, name: 'gIRr' , format: r => z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getInstallReferrer, 2)?.toUpperCase()                                                                                 , selector: 'DS', sortable: false }
// , { grow: 1, name: 'Fingerprint' , format: r => z.nu(r?.DS?.DevInfo?.DevInfoAsync?.getFingerprint, 2)?.toUpperCase()                                                                                 , selector: 'DS', sortable: false }
//, { grow: 3, name: 'Address' , format: r => r?.sync?.Address ?? r?.sync?.Addresses?.[0]                                                                          , selector: 'sync', sortable: false }
    ,
    //, { name: 'Year', selector: 'year', sortable: true, right: true, },
];



class z {
    
    static nu = (r) => r == 'unknown' ? '' : r;
    static nu = (r, l) => r == 'unknown' ? '' : r?.substring(0, l);
    
    static nx = (str) => str?.length > 0 ? str : null;


    static toNum = (ver) => {
        if (ver == undefined) {
            return -1;
        }
        ver = ver.replace('.', '^');
        ver = ver.replace('.', '');
        ver = ver.replace('^', '.');
        let rs = Number.parseFloat(ver);
        return rs;
    }

    static combine = (ls, strs, sepa, plcHolder) => {
        let rs = '';
        for (let i = 0; i < ls.length; i++) {
            let last = i == (ls.length - 1);
            let l = ls?.[i] ?? 5; let str = strs?.[i] ?? '';
            for (let li = 0; li < l; li++) {
                rs += !!(str[li]) ? str[li] : (last ? ' ' : plcHolder);
            }
            rs += last ? '' : sepa;
        }
        return rs;
    }

    static mapModel = (model) => {
        //if (model?.substring(0, 6) == 'iPhone') { return 'iP ' + model.substring(6, 99) }
        return glob.dev_model_map?.[model] ?? model;
    }

    static find_mapped = (r) => {
        return (!!(glob.dev_model_map?.[r?.DS?.DevInfo?.getModel]) ? '☑️' : '');
        return (!!(glob.dev_map?.[r.dev]) ? '☑️' : '')
         + (!!(glob.dev_model_map?.[r?.DS?.DevInfo?.getModel]) ? '☑️' : '')
    }
    static find_private = (r) => {
        let R = r?.sync;
        return ((!!(R?.Address) || R?.Addresses?.length > 0 ? '🗺️' : '') + '' + (!!(R?.Mobile) ? '📞' : ''))
    }
    static find_capab_str = (r) => {
        let R = r?.DS?.DevInfo?.DevInfoAsync;
        return (R?.isLocationEnabled ? '📍' : '') + '' + (R?.isPinOrFingerprintSet ? '👆' : '')
    }
    static find_RUNNING_str = (r) => {
        let R = r?.DS?.codePushMetas?.RUNNING;
        if (!R || R == 'N/A') { return ''; }
        let _curVer = R?.description; let preFix = ' ';
        try {
            let _reqVer = r?.isIOS ? r?.reqVers?.ios : r?.reqVers?.adr;
            let cv = _curVer.split(".");
            if (Number.parseInt(cv[0]) < _reqVer.core) { preFix = '⚠️'; }
            else if (Number.parseInt(cv[1]) < _reqVer.minor) { preFix = '🔼'; }
        }
        catch(err) { }
        return preFix + ' ' + _curVer + '   ' + keyDic[R?.dk];
    }
    static find_BINARY_str = (r) => {
        let _curVer = r?.DS?.DevInfo?.getVersion; let preFix = ' ';
        try {
            let _reqVer = r?.isIOS ? r?.reqVers?.ios : r?.reqVers?.adr;
            let cv = _curVer.split(".");
            if (Number.parseInt(cv[0]) < _reqVer.core) { preFix = '⚠️'; }
            else if (Number.parseInt(cv[1]) < _reqVer.minor) { preFix = '🔼'; }
        }
        catch(err) { }
        return preFix + _curVer;
    }
}


const keyDic = {
    '8t8ZUmX': '',
    '7Vc3QQ1': 'A🛠️',
    'Elp4bMj': '',
    'KOm_zgv': 'I🛠️',
}
const keyDicShort = {
    '8t8ZUmX': '',
    '7Vc3QQ1': '🛠️',
    'Elp4bMj': '',
    'KOm_zgv': '🛠️',
}
export const ADR_PROD_KEY = '8t8ZUmX'   ;
export const ADR_STAG_KEY = '7Vc3QQ1'    ;
export const IOS_PROD_KEY = 'Elp4bMj'    ;
export const IOS_STAG_KEY = 'KOm_zgv'   ;















