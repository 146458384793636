import APITools from './../Operators/APITools';
import T from "./../T";

const aha_api_requestInit = () => { return { method: 'GET', headers: { 'Content-type': 'application/json', 'User-Agent': "AHA Cafe/Android" } }; }

const CACHE_KEY = 'manage.get.cache';
const loadCache = () => {
  return JSON.parse(localStorage.getItem(CACHE_KEY) ?? '{}');
}

export default class jv_APICalls {

    static _mc = null;
    static manageCache = () => {
        if (jv_APICalls._mc == null) {
          T.l('loadCache');
          jv_APICalls._mc = loadCache();
        }
        return jv_APICalls._mc;
    }
    static writeCache = () => {
        //localStorage.setItem(CACHE_KEY, JSON.stringify(jv_APICalls._mc));
    }

  
    static manage_get = (_app, _dev) => {
        return new Promise((resolve, reject) => {

          let cache = jv_APICalls.manageCache();
          let find = cache[_app + _dev];

          if (!!(find) && ((+new Date() - find.timeGot) < 3600000 )) {
            resolve(find);
          }
          else {
            
            fetch(`https://api.jvjsc.com:5640/manage/get?app=${_app}&dev=${_dev}`)
            .then(response => response.text())
            .then(txt => {
              try {
                let sp = jv_APICalls.split(txt);
                let obj = JSON.parse(sp[1]); obj.timeGot = +new Date();
                resolve(obj);
                cache[_app + _dev] = obj;
                jv_APICalls.writeCache();
              }
              catch(err) {
                resolve({ /**/ });
              }
            })
            .catch(err => {
                T.l("err 19 for " + _dev, err); resolve({ /**/ });
            });

          }


        });
    }

    static split = (txt) => {
        try {
          let rs = txt?.split('¦');
          return [ rs[0], rs[1] ];
        }
        catch(err) { T.l('split >' + txt + '<' , err); return [ Number.MAX_SAFE_INTEGER, 'could not read entry err: ' + err ] }
      }
}